import React, { useEffect, useRef } from 'react';
import './Features.css';
import CardWithImage from './CardWithImage';

import securityAlgos from '../Images/SecurityAlogs.png'
import connections from '../Images/Connections.png'
import securityScore from '../Images/SecurityScore.png'
import liveMonitor from "../Images/LiveMonitor.png";
import fingerprint from "../Images/Fingerprint.png";
import notifications from "../Images/Notifications.png";

const Features = () => {
  const sectionRefs = useRef([]); // To store refs to each article

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Add the 'in-view' class when the article reaches the center of the viewport
              entry.target.classList.add('in-view');
            }
          });
        },
        {
          threshold: 1, // Trigger when 50% of the article is visible
        }
    );

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
      <div className="light-wrapper">
      <div className="container">
        <section className="features">
            {[
                {
                    title: 'Diagnose Clear Problem Areas, When Problems Arise',
                    subtitle: 'Intuitive Diagnostics',
                    img: liveMonitor,
                    imgAlt: 'Heartbeat testing',
                    imagePosition: "left",
                    content: [
                        'When things go wrong, ambiguous error codes and finger-pointing between teams can be frustrating and lengthen the resolution time.',
                        'Threpoly’s synthetic usage feature authenticates into your solution and simulates real-world traffic & behaviour. This gives us the insight as to where the issue lies, whether it be network, protocol, application or something else.',
                    ],
                },
                {
                    title: 'Plan for Increased Traffic Levels Without Fear of Bottlenecks',
                    subtitle: 'Business Insights',
                    img: fingerprint,
                    imgAlt: 'Multi-factor Authentication',
                    imagePosition: "right",
                    content: [
                        'You want to support your growing business with increased file transfer and data exchange traffic, but the strategy is to throw more-and-more at your solution and network, hoping they will continue to work…',
                        'Threpoly provides a birds-eye view of performance statistics and failed connections so you can better plan for increases in traffic and be confident of the outcome.',
                    ],
                },
                {
                    title: 'Get a List of Problem Areas to Resolve',
                    subtitle: 'Compliance',
                    img: notifications,
                    imgAlt: 'Synthetic usage and security',
                    imagePosition: "left",
                    content: [
                        'Flashy graphs and tons of information are great to look at, but what do they mean and how can they be used for genuine improvement in service availability and security?',
                        'With Threpoly’s notification feature, you get a concise list of possible improvements, in order of impact. Taking all the work out of chart reading and getting you onto the road of optimization as soon as possible.',
                    ],
                },
          ].map((article, index) => (
              <article
                  key={index}
                  ref={(el) => (sectionRefs.current[index] = el)}
                  className="feature-article hidden" // Initially hidden
              >
                <CardWithImage
                    title={article.title}
                    subtitle={article.subtitle}
                    img={article.img}
                    imgAlt={article.imgAlt}
                    imagePosition={article.imagePosition}
                    onLight={true}
                >
                  {article.content.map((text, i) => (
                      <p key={i}>{text}</p>
                  ))}
                </CardWithImage>
              </article>
          ))}
        </section>
      </div>
      </div>
  );
};

export default Features;

import React from 'react';
import './DownloadDataSheet.css';
import downloadIcon from '../Images/download.png';

const DownloadDataSheet = ({ }) => {
    return (
        <div className="download-datasheet-wrapper">
            <div className="download-datasheet-container">
                <h2 className="download-data-sheet-title">Download the data sheet</h2>
                <div className="download-data-button">
                    <img src={downloadIcon} className="download-icon" alt="Download" />
                </div>
            </div>
        </div>
    );
};

export default DownloadDataSheet;

import React, { useEffect, useRef } from 'react';
import './Features.css';
import CardWithImage from './CardWithImage';

import liveMonitor from '../Images/LiveMonitor.png'
import fingerprint from '../Images/Fingerprint.png'
import notifications from '../Images/Notifications.png'
import securityAlgos from "../Images/SecurityAlogs.png";
import connections from "../Images/Connections.png";
import securityScore from "../Images/SecurityScore.png";

const Features = () => {
  const sectionRefs = useRef([]); // To store refs to each article

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Add the 'in-view' class when the article reaches the center of the viewport
              entry.target.classList.add('in-view');
            }
          });
        },
        {
          threshold: 1, // Trigger when 50% of the article is visible
        }
    );

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
      <div className="container">
        <section className="features">
          <h1 id="features">Always Remain Open for Business</h1>
          <h4 className='features-overview-text'>
            Whether you use an MFT tool to allow customers to upload or download resources, have an EDI solution powering your orders and invoicing operations, or for any other use your business relies on these critical workflows and functions to trade, remain competitive and grow.
            <br/><br/>
            Threpoly gives you with insights into security and performance information, not natively available in those solutions.
          </h4>

            {[
                {
                    title: 'Keep IT Security at-Bay Knowing Your Communications are Secure',
                    subtitle: 'Security',
                    img: securityAlgos,
                    imgAlt: 'Heartbeat testing',
                    imagePosition: "left",
                    content: [
                        'Security recommendations change over time, and what was secure may no longer be - putting you at risk of compliance failures, or worse, unable to connect to trading partners.',
                        'Threpoly assesses your SFTP and FTPS security strength against a list of vulnerable ciphers and algorithms, giving you an indication of your current standing and how to improve.',
                    ],
                },
                {
                    title: 'Never Be Last to Discover Slowdowns & Outages',
                    subtitle: 'Proactive Approach',
                    img: connections,
                    imgAlt: 'Multi-factor Authentication',
                    imagePosition: "right",
                    content: [
                        'You and your trading partners need to be confident that there will be no interruption to day-to-day operations. Outages cost time, money and reputation.',
                        'Threpoly uses up-to three globally distributed heartbeat monitors to track the performance of SFTP and FTPS services over time, highlighting & notifying of patterns of slowdown or outages - before your customers and suppliers report them to you.',
                    ],
                },
                {
                    title: 'A Single Metric to Convey Confidence and Health',
                    subtitle: 'Business Confidence',
                    img: securityScore,
                    imgAlt: 'Synthetic usage and security',
                    imagePosition: "left",
                    content: [
                        'We have a ton of features - like many solutions - but you and your leaders want abbreviated indicators to tell them all is well.',
                        'Using all the data we collect, we grade your solution from A-G. Either giving you confidence in your ability to keep up with business demands and bragging rights; or giving you direction for improvement.',
                    ],
                },
          ].map((article, index) => (
              <article
                  key={index}
                  ref={(el) => (sectionRefs.current[index] = el)}
                  className="feature-article hidden" // Initially hidden
              >
                <CardWithImage
                    title={article.title}
                    subtitle={article.subtitle}
                    img={article.img}
                    imgAlt={article.imgAlt}
                    imagePosition={article.imagePosition}
                >
                  {article.content.map((text, i) => (
                      <p key={i}>{text}</p>
                  ))}
                </CardWithImage>
              </article>
          ))}
        </section>
      </div>
  );
};

export default Features;

import React, { useState } from 'react';
import './FAQ.css';

const PricingFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is a Target?",
      answer: `A Target is a combination of a hostname / IP address and a TCP port, which your data transfer solution is listening on for incoming connections.
      
      It may be easier to think of a Target being a single server, however in some cases it might be a load-balancer with multiple servers behind it or it might be a multi-homed server with multiple IP addresses on a single server.
      
      If you need any assistance deciding, we are here to help!`
    },
    {
      question: "What is a Cloud-Based Monitoring Agent?",
      answer: `One of the core features of Threpoly is its ability to record latency and connectivity statistics by connecting to your Target periodically. We can do this from one or more geographical locations using our Cloud-Based Monitoring Agent to give you the full picture of the experience your trading partners and customers have when connecting.`
    },
    {
      question: "Where are your Cloud-Based Monitoring Agents Located?",
      answer: `London, California and Seoul - if you would like to have a custom located agent, please contact our Sales Team who will be able to provide a quote.`
    },
    {
      question: "My Requirements Fall Between Two Plans, What Should I do?",
      answer: `We wanted to keep our offering and this page as simple and easy-to-understand as possible - and so we have omitted some choices. We do permit customisation of the Professional and Premium plans to your needs. Please get in touch with our Sales Team to discuss your requirements further.`
    },
    {
      question: "Why is there a Difference Between the Annual and Monthly Prices?",
      answer: `Monthly payment plans increase our operational overheads and require the use of a payment gateway, both of which increase our costs. Annual payments are quick, simple and once per year, so we reward those customers with a ~20% discount.`
    }
  ];

  return (
      <div className="container">
        <div className='faq-container'>
          <h1 className="faq-title">Frequently Asked Questions</h1>
          {faqItems.map((item, index) => (
              <div key={index} className="faq-item">
                <button
                    className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                    onClick={() => toggleAccordion(index)}
                >
                  {item.question}
                </button>
                <div
                    className={`faq-answer ${activeIndex === index ? 'open' : ''}`}
                    style={{ maxHeight: activeIndex === index ? '900px' : '0' }}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
          ))}
        </div>
      </div>
  );
};

export default PricingFAQ;

import React, { useState, useEffect } from 'react';
import classes from './ExampleWidget.module.css';
// Removed 'animate.css' if not used elsewhere, otherwise keep it

const ExampleWidget = () => {
  const staticServers = [
    { connectionID: 1, name: 'SFTP', todayAverage: 3.5, difference: -12, historicalAverages: [4.0, 3.8, 4.2, 4.1, 3.9, 3.5, 3] },
    { connectionID: 2, name: 'FTPS', todayAverage: 7.8, difference: 12, historicalAverages: [6.0, 6.3, 6.2, 6.5, 6.1, 6.4, 6.6] }
  ];

  const [barHeights, setBarHeights] = useState(staticServers.map(() => ({ today: 0, historical: Array(7).fill(0) })));
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    staticServers.forEach((_, serverIndex) => {
      setTimeout(() => {
        setBarHeights(prev => {
          const newHeights = [...prev];
          newHeights[serverIndex].today = staticServers[serverIndex].todayAverage;
          return newHeights;
        });

        staticServers[serverIndex].historicalAverages.forEach((_, histIndex) => {
          setTimeout(() => {
            setBarHeights(prev => {
              const newHeights = [...prev];
              newHeights[serverIndex].historical[histIndex] = staticServers[serverIndex].historicalAverages[histIndex];
              return newHeights;
            });
          }, histIndex * 200);
        });
      }, serverIndex * 500);
    });

    // Calculate total delay: server delays + historical bar delays
    const serverDelay = staticServers.length * 500;
    const historicalDelay = Math.max(...staticServers.map(s => s.historicalAverages.length)) * 200;
    const totalDelay = serverDelay + historicalDelay ; // Additional buffer

    const animationTimeout = setTimeout(() => {
      setAnimationStarted(true);
    }, totalDelay);

    // Cleanup the timeout on unmount
    return () => clearTimeout(animationTimeout);
  }, []);

  const exaggerationFunction = (value) => Math.pow(value, 2);
  const getBarColor = (difference) => difference <= 0 ? 'var(--green)' : 'var(--orange)';

  return (
      <div className={classes.container}>
        <div className={classes.subCard}>
          <div className={classes.overviewWrapper}>
            {staticServers.map((server, serverIndex) => {
              const maxTime = Math.max(server.todayAverage, ...server.historicalAverages) || 1;
              const scaledTodayHeight = exaggerationFunction(barHeights[serverIndex].today / maxTime) * 90;

              return (
                  <div className={classes.individualOverviewItem} key={serverIndex}>
                    <span className={classes.overviewItemTitle}>{server.name}</span>
                    <span className={server.difference > 10 ? classes.performanceTextBad : server.difference < -10 ? classes.performanceTextGood : classes.performanceText}>
                  {server.difference > 10 ? 'Slower than usual' : server.difference < -10 ? 'Faster than usual' : 'Performing as expected'}
                </span>
                    <div className={classes.comparisonInfoArea}>
                      <div className={classes.barContainer}>
                        <div
                            className={`${classes.bar} ${classes.todayAverageBar}`}
                            style={{
                              height: scaledTodayHeight ? `${scaledTodayHeight}%` : '0%',
                              backgroundColor: getBarColor(server.difference),
                              ...(animationStarted && {
                                animation: 'barGrow 10s ease-in-out infinite',
                                animationDelay: `${serverIndex * 0.5}s`,
                              }),
                            }}
                        />
                      </div>
                      <span className={classes.barTag}>Current Data ({server.difference}%)</span>

                      {server.historicalAverages.map((historicalAverage, histIndex) => {
                        const scaledHistoricalHeight = exaggerationFunction(barHeights[serverIndex].historical[histIndex] / maxTime) * 90;

                        return (
                            <div className={classes.barContainer} key={histIndex}>
                              <div
                                  className={`${classes.bar} ${classes.barHistorical}`}
                                  style={{
                                    height: scaledHistoricalHeight ? `${scaledHistoricalHeight}%` : '0%',
                                    backgroundColor: '#808284',
                                    ...(animationStarted && {
                                      animation: 'barGrow 10s ease-in-out infinite',
                                      animationDelay: `${serverIndex * 0.5 + histIndex * 0.2}s`,
                                    }),
                                  }}
                              />
                            </div>
                        );
                      })}
                    </div>

                    {/* Static CompareTimesInfoArea */}
                    <div className={classes.compareTimesInfoArea}>
                      <p><span className={classes.statusInfoAreaTitle}>Now:</span><br />
                        <span style={{ color: getBarColor(server.difference) }}>
                      {server.todayAverage}s {server.difference}%
                    </span>
                      </p>
                      <p><span className={classes.statusInfoAreaTitle}>Historical:</span><br />
                        {server.historicalAverages[0]}s Avg.
                      </p>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
      </div>
  );
};

export default ExampleWidget;

// MobileNav.js
import React from 'react';
import './MobileNav.css';
import logo from '../threpoly-logo-dark.svg';

const MobileNav = ({ toggleMenu }) => {
  return (
    <div className="mobile-nav">
      <div className='nav-items'>
        <a href="/" onClick={toggleMenu}>Home</a>
        <a href="/pricing" onClick={toggleMenu}>Pricing</a>
        <a href="https://www.articles.threpoly.com" onClick={toggleMenu}>Resources</a>
        <a href="/company" onClick={toggleMenu}>Company</a>
        <a href="/contact" onClick={toggleMenu}>Contact</a>
        <a href="https://threpoly.statuspage.io/" target='_blank' onClick={toggleMenu}>Status</a>
      </div>
    </div>
  );
};

export default MobileNav;

import React, { useEffect, useRef } from 'react';
import './Company.css';
import './Sustainability.css';

import globalGoalsLogo from '../Images/Global_Goals_Logo.webp';
import liveMonitor from "../Images/LiveMonitor.png";
import fingerprint from "../Images/Fingerprint.png";
import notifications from "../Images/Notifications.png";
import treePlanting from "../Images/TreePlanting.png"; // Add your tree planting image
import paperless from "../Images/Paperless.png"; // Add your paperless operations image
import remoteWork from "../Images/RemoteWork.png"; // Add your remote work image
import CardWithImageAlternate from "./CardWithImageAlternate";

const Sustainability = () => {
    const sectionRefs = useRef([]); // To store refs to each article

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Add the 'in-view' class when the article reaches the center of the viewport
                        entry.target.classList.add('in-view');
                    }
                });
            },
            {
                threshold: 0.5, // Trigger when 50% of the article is visible
            }
        );

        sectionRefs.current.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            sectionRefs.current.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    const articles = [
        // New Article 1
        {
            title: 'Life on Land',
            subtitle: 'Tree Planting for Every Minute of Downtime',
            img: treePlanting, // Ensure you have this image in your Images directory
            imgAlt: 'Tree planting initiative',
            imagePosition: "left",
            content: [
                'We are committed to making a positive impact by donating to tree planting initiatives for every minute of SLA downtime caused by our service or technical support. Beyond the environmental benefits, who wouldn’t appreciate the beauty of a thriving forest?',
            ],
        },
        // New Article 2
        {
            title: 'Responsible Consumption and Production',
            subtitle: 'Commitment to Reducing Waste',
            img: paperless, // Ensure you have this image in your Images directory
            imgAlt: 'Paperless office',
            imagePosition: "left",
            content: [
                'We are an IT business and as such, it is our commitment to ensure all our business operations are paperless to reduce waste and consumption. Everything we do is electronic and we don’t even own a printer!',
            ],
        },
        // New Article 3
        {
            title: 'Sustainable Cities and Communities',
            subtitle: 'Efficiency and Sustainability',
            img: remoteWork, // Ensure you have this image in your Images directory
            imgAlt: 'Remote working team',
            imagePosition: "left",
            content: [
                'Our default working model is remote, and we believe this accomplishes two key goals: it frees our team from wasting unproductive hours commuting, and it reduces our environmental footprint by eliminating the need for unsustainable transportation.',
            ],
        },
    ];

    return (
        <div className="light-wrapper">
            <div className="container" style={{display: 'flex', flexDirection: 'column', paddingBottom:'10rem'}}>
                <h1 className='page-title' style={{ paddingTop: '4rem'}}>Sustainability</h1>
                <p className='page-sub-heading' style={{ maxWidth: '48rem', marginInline: 'auto' }}>
                    In 2015, all United Nations member states adopted the 2030 Agenda for Sustainable Development.<br />
                    As a responsible business, we are doing our bit to support the 17 Goals.
                <br/><br/>
                    Read more about Global Goals <a href='https://www.globalgoals.org/goals' target='_blank'>here</a>.
                </p>
                <img className='global-goals-logo' src={globalGoalsLogo} alt="Global Goals" />

                {articles.map((article, index) => (
                    <article
                        key={index}
                        ref={(el) => (sectionRefs.current[index] = el)}
                        className="feature-article hidden" // Initially hidden
                    >
                        <CardWithImageAlternate
                            title={article.title}
                            subtitle={article.subtitle}
                            img={article.img}
                            imgAlt={article.imgAlt}
                            imagePosition={article.imagePosition}
                            onLight={true}
                        >
                            {article.content.map((text, i) => (
                                <p key={i}>{text}</p>
                            ))}
                        </CardWithImageAlternate>
                    </article>
                ))}

            </div>
        </div>
    );
};

export default Sustainability;

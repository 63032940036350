import React, { useState } from 'react';
import './Header.css';
import logoDark from '../threpoly-logo-dark.svg';
import logoLight from '../threpoly-logo-light.svg'; // assuming you have a light version of the logo
import MobileNav from './MobileNav';

const Header = ({ theme }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isDarkMode = theme === 'dark';

  return (
      <header className={isDarkMode ? 'dark-mode' : 'light-mode'}>
        <div className="container">
          <a href="/"><img src={!isDarkMode ? logoDark : logoLight} className="logo" alt="Threpoly Logo" /></a>
          <button className="burger-menu" onClick={toggleMenu}>
            ☰
          </button>
          <nav>
            <a href="/">Home</a>
            <a href="/pricing">Pricing</a>
            <a href="https://www.articles.threpoly.com">Resources</a>
            <a href="/company">Company</a>
            <a href="/contact">Contact</a>
            <a href="https://threpoly.statuspage.io/" target='_blank'>Status</a>
            <a href="https://dashboard.threpoly.com/login-dashboard" target="_blank">
              <button className='login-button'>Log in</button>
            </a>
          </nav>
        </div>
        {menuOpen && <MobileNav toggleMenu={toggleMenu} />}
      </header>
  );
};

export default Header;

import React from 'react';
import './Company.css';

const Company = () => {


    return (
        <div className="container">
            <h1 className='page-title' style={{paddingTop: '16rem', color: 'var(--text-color', marginTop: '0'}}>Threpoly keeps watch, <span style={{color: 'var(--secondary-color'}}>so you don't have to.</span></h1>
            <div className='company-page-main-text-wrapper'>
                <p className='company-page-main-text'>Today’s expectations are high. Customers and Trading Partners need 24 hour availability; Business leaders demand high levels of growth… and both are unforgiving when it comes to dips or outages in critical IT services.</p>
                <p className='company-page-main-text'>Managing file transfer and data exchange infrastructure can be a tough gig. Most solutions are great at functionally moving files from A-to-B, but what about when it goes wrong? Or someone reports slow transfers? Your business is losing money but is it the application, the network or something external? And don’t forget to update your stakeholders every 30 minutes while you are trying to focus on fixing it!</p>
                <p className='company-page-main-text'>You want the business to be successful and for your investments in effort and money to be recognised by your leaders and trading partners. You want technology to make your life easier and for things to either work or be proactively notified of problems before someone else reports it to you (a little embarrassing).</p>
                <p className='company-page-main-text'>Get confident in your ability to support the business, stand proud as the trading partner who offers the most consistent service and best of all, reduce all that time firefighting. Threpoly proactively monitors your file transfer and data exchange infrastructure for availability and security issues - turning stressful “diagnose and fix” into blood-pressure reducing “maintain and monitor”.</p>
            </div>
            <div className='company-info-container'>
                <div className='company-graphic'>
                    <div className='company-graphic-box-wrapper'>
                        <div className='company-graphic-box-dash-dark'></div>
                        <div className='company-graphic-box'></div>
                        <div className='company-graphic-box-dash-light'></div>
                    </div>
                    <div className='company-graphic-line'></div>
                    <div className='company-graphic-box-wrapper'>
                        <div className='company-graphic-box-dash-dark'></div>
                        <div className='company-graphic-box'></div>
                        <div className='company-graphic-box-dash-light'></div>
                    </div>
                </div>
                <div className='company-info-text'>
                    <h2 className='page-sub-title' style={{textAlign: 'left'}}>Where it all Began</h2>
                    <br/>
                    <p className='company-page-sub-text'>Three of our founders, having dedicated much of their careers to the file transfer industry, eventually crossed paths.</p>

                    <p className='company-page-sub-text'>Having worked with small local government authorities, large financial enterprises and everything in between, installing and maintaining file transfer and data exchange across the globe - we have seen first-hand the challenges which come with ensuring optimal services and the increasing reliance of trading availability.</p>

                    <p className='company-page-sub-text'>The challenges we saw when working with our customers; our 35+ years of collective experience in this industry; and our passion for problem solving led us to create Threpoly, the World’s first and only dedicated file transfer and data exchange performance and security monitoring solution.</p>

                    <p className='company-page-sub-text'>Threpoly was founded on the fundamental principle that it is not a cog in the machine of your operations, it is the oil which makes those cogs perform their best and to your expectations.
                    </p>
                    <br/>
                    <h2 className='page-sub-title' style={{textAlign: 'left'}}>Today</h2>
                    <br/>
                    <p className='company-page-sub-text'>Although Threpoly is the new kid on the block, Threpoly is being used by a major retailer headquartered in the US; and has a comprehensive and innovative roadmap which its founders, customers and prospects have collaborated to help shape.</p>
                </div>
            </div>

        </div>
    );
};

export default Company;

import React from 'react';
import './CardWithImageAlternate.css';

const cardWithImageAlternate = ({ title, subtitle, children, img, imgAlt, imagePosition = 'left', onLight }) => {
    // Compute container classes based on imagePosition and theme
    const containerClasses = `
        cardWithImageAlternate-container 
        ${onLight ? 'cardWithImageAlternate-container-light' : ''} 
        ${imagePosition === 'right' && !onLight ? 'card-left' : !onLight ? 'card-right' : null}
    `;

    // Compute image classes based on imagePosition and theme
    const imgClasses = imagePosition === 'right'
        ? `cardWithImageAlternate-placeholder-right ${onLight ? 'cardWithImageAlternate-placeholder-right-light' : ''} order-right`
        : `cardWithImageAlternate-placeholder ${onLight ? 'cardWithImageAlternate-placeholder-light' : ''}`;

    // Compute content classes based on imagePosition and theme
    const contentClasses = `
        cardWithImageAlternate-content 
        ${onLight ? 'cardWithImageAlternate-content-light' : ''} 
        ${imagePosition === 'right' ? 'order-left' : ''}
    `;

    // Compute subtitle and title classes for theme support
    const subtitleClasses = `cardWithImageAlternate-subtitle ${onLight ? 'cardWithImageAlternate-subtitle-light' : ''}`;
    const titleClasses = `cardWithImageAlternate-title ${onLight ? 'cardWithImageAlternate-title-light' : ''}`;

    return (
        <div className={containerClasses}>
            <img
                src={img}
                alt={imgAlt}
                className={imgClasses}
            />
            <div className={contentClasses}>
                <h2 className={titleClasses}>{title}</h2>
                <div className={`cardWithImageAlternate-body ${onLight ? 'cardWithImageAlternate-body-light' : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default cardWithImageAlternate;
